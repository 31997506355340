import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {HttpClientModule} from "@angular/common/http";
import {AuthStorageService, isLoggedAdmin, isLoggedIn, isLoggedOut} from "./auth-storage.service";

const routes: Routes = [
  {
    path: 'login',
    canActivate: [isLoggedOut],
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [isLoggedIn]
  },
  {
    path: 'create-incident',
    loadChildren: () => import('./create-incident/create-incident.module').then( m => m.CreateIncidentPageModule),
    canActivate: [isLoggedIn]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [isLoggedIn]
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule),
    canActivate: [isLoggedIn]
  },
  {
    path: 'incident/:uuid',
    loadChildren: () => import('./incident/incident.module').then( m => m.IncidentPageModule),
    canActivate: [isLoggedIn]
  },
  {
    path: 'incident-edit/:uuid',
    loadChildren: () => import('./incident-edit/incident-edit.module').then( m => m.IncidentEditPageModule),
    canActivate: [isLoggedIn]
  },
  {
    path: 'comms',
    loadChildren: () => import('./comms/comms.module').then( m => m.CommsPageModule),
    canActivate: [isLoggedIn]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule),
    canActivate: [isLoggedAdmin]
  },
  {
    path: 'user/:uuid',
    pathMatch: 'full',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule),
    canActivate: [isLoggedAdmin]
  },
  {
    path: 'user/:uuid/edit',
    loadChildren: () => import('./user-edit/user-edit.module').then( m => m.UserEditPageModule),
    canActivate: [isLoggedAdmin]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then( m => m.MaintenancePageModule),
    canActivate: [isLoggedAdmin]
  },
  {
    path: 'confirm-email/:email',
    canActivate: [isLoggedIn],
    loadChildren: () => import('./confirm-email/confirm-email.module').then( m => m.ConfirmEmailPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    HttpClientModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
