import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {AuthStorageService} from "./auth-storage.service";
import {Storage} from "@ionic/storage-angular";
import {NgHcaptchaModule} from "ng-hcaptcha";
import {environment} from "../environments/environment";

@NgModule({
  declarations: [AppComponent],
  imports:
    [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
      NgHcaptchaModule.forRoot({
        siteKey: environment.hcaptcha.siteKey,
        languageCode: 'es',
      })],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthStorageService, Storage,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
